import React from 'react'
import VideoRecorder from 'react-video-recorder'
import FileSaver from 'file-saver';


function App() {

  const [videoFile, setVideoFile] = React.useState(null);

  const onVideoRecordingComplete = videoBlob => {
    // Do something with the video...
    /* TODO: replace the blob content with your byte[] */
    var blob = new Blob([videoBlob], { mimeType: "audio/webm" });
    var fileName = "myFileName.webm";
    FileSaver.saveAs(blob, fileName);
  }

  return (
    <div id="App">
      <VideoRecorder data-ref="videoRecorder" onRecordingComplete={onVideoRecordingComplete}  />



    </div>
  );
}

export default App;
